/* eslint-disable array-callback-return */
/***components***/
import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { toast } from "mdbreact";

/*custom components*/
import PageLoading from "../../components/PageLoading";
import Category from "../../components/Category";
import Card from "../../components/Card";
import NotFounded from "../../components/NotFounded";
/*treeview component*/
import TreeMenu from "react-simple-tree-menu";
import "../../assets/css/main-treeview.css";
import InfiniteScroll from "react-infinite-scroll-component";
import { Helmet } from "react-helmet";
/*functions*/
import {
  getStoresInfo,
  getShopCart,
  getCurrentCatalog,
  manageCart,
  formatCategory,
  find,
  getSyncStoresInfo,
  formatPathCategory,
  setGTMEvent,
  setStore,
  showMessage,
  getImagePath,
  getLoginInfo,
  findValueById,
  formatPathName,
  getDefValues,
  buildProductsCard,
  getSortMethods,
  managesort,
  managepagination,
  getLanguage,
  setSpanLabel,
  searchParent,
  callApi,
} from "../../common/functions";

class page extends Component {
  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      categoryid: "",
      newcategoryid: "",
      showMenu: false,
      categoryLabel: "",
      categoryDescription: "",
      categoryDescriptionH: "",
      categoryImage: "",
      productNotFounded: false,
      showCategoryMenu: false,
      isLoading: true,
      MetaTags: {},
      activeNodes: [],
      initialActiveKey: "",
      categoryHomeLabel: "",
      treeDataMenu: [],
      redirect: false,
      searchkey: "",
      redirectProductDetails: false,
      settingsSlider: {
        dots: false,
        adaptiveHeight: true,
        arrows: true,
        autoplay: false,
        cssEase: "linear",
        slidesToShow: 3,
        slidesToScroll: 1,
        speed: 500,
        divWidth: "card col-md-12 col-lg-12 col-xl-12",
        specialArrows: "2",
        outside: "YES",
        spaceitems: "YES",
      },
      cardConfig: {},
      cardData: [],
      messages: [],
      selectedProductID: 0,
      selectedPath: "",
      newPath: "",
      selectedCategory: "",
      categoryObj: {
        orgName: process.env.REACT_APP_ORG_NAME,
        categoryRouteObj: [],
      },
      mainMenu: [],
      sortmethod: "new",
      prodDefConfig: {
        pagewidth: "col-md-12 col-lg-12 col-xl-12",
        colsize: "col-6",
        colsizelg: "col-lg-2_5",
        colsizemd: "col-md-4",
        colsizesm: "col-sm-6",
        limit: 10,
        /*overtop           : "YES"*/
      },

      items: Array.from({ length: 10 }),
      hasMore: true,
      start: 0,
      end: 0,
      noCategory: false,
      treeMenu: [],
      aditionalInfo: "",
      action: "",
    };
  }

  fetchMoreData = () => {
    let cardConfig = this.state.cardConfig;
    if (this.state.items.length >= this.state.cardData.length) {
      cardConfig["limit"] = this.state.cardData.length;
      this.setState({ hasMore: false, cardConfig: cardConfig });

      return;
    } else {
      cardConfig["limit"] = this.state.items.length;
      this.setState({
        cardConfig: cardConfig,
      });
    }

    // a fake async api call like which sends
    // 20 more records in .5 secs
    setTimeout(() => {
      this.setState({
        items: this.state.items.concat(Array.from({ length: 10 })),
      });
    }, 500);
  };

  /*loading data*/
  componentDidMount = async () => {
    //console.log("HI")
    if (isNaN(this.props.match.params.categoryid)) {
      this.setState({ ...this.state, noCategory: true });
      //console.log("no c")
    } else {
      /*********BLOOMREACH*********/
      let url = window.location.href;
      let urlObj = new URL(url);
      let pathParts = urlObj.pathname.split("/");
      var objBloomreach_ViewCategory = {
        origen: "web",
      };
      let lastSegment = pathParts[pathParts.length - 1];
      if (!isNaN(lastSegment)) {
        pathParts.splice(pathParts.length - 1, 1);
      }
      if (pathParts[1]) {
        objBloomreach_ViewCategory["cat_espacios_level_1"] = pathParts[1];
      }
      if (pathParts[2]) {
        objBloomreach_ViewCategory["cat_espacios_level_2"] = pathParts[2];
      }
      if (pathParts[3]) {
        objBloomreach_ViewCategory["cat_espacios_level_3"] = pathParts[3];
      }
      window.exponea.track("view_category", objBloomreach_ViewCategory);
      /*********BLOOMREACH*********/

      getShopCart("VIEWCATEGORY");

      var storeid = getCurrentCatalog();

      //var aditionalInfo = this.props.location.search;
      var eventId = this.props.eventId;
      var action = this.props.action;

      //console.log("ac", this.props.action);
      if (action !== "") {
        try {
          let resp2 = await callApi("frontend", "getCustomerEventById", {
            languageid: getLanguage(),
            eventid: eventId,
          });
          const prodDefConfig = this.state.prodDefConfig;
          prodDefConfig["action"] = action;
          prodDefConfig["id"] = eventId;

          var fixedStoreid = resp2.EVENT.STOREID;
          var storeInfo = getSyncStoresInfo();
          this.setState({
            action: action,
          });

          let fixedCatalogId = findValueById(
            storeInfo.stores,
            fixedStoreid,
            "storeId",
            "catalogId"
          );

          //console.log("store update",storeid,fixedStoreid, storeInfo , fixedCatalogId)

          if (storeid !== fixedCatalogId) {
            //console.log("stre update"  )
            storeid = fixedCatalogId;
            let resp = await setStore(fixedStoreid);
            this.props.updateStore(resp.storesInfo);
            showMessage(toast, getDefValues().storeChangedByEventMessage);
          }
        } catch (err) {
          console.log("INVALID EVENT OR TOKEN EXPIRED", err);
        }
      }

      this.props.hideCart();
      await getStoresInfo();
      let resp = await callApi("customer", "getCategoriesByStore", {
        languageid: getLanguage(),
        storeid: storeid,
      });
      var menu = resp.rows;

      var menu2 = [];
      menu.map(function (key) {
        if (key.statusid == 5) {
          menu2.push(key);
        }
      });

      var pos = find(menu2, this.props.match.params.categoryid, "CATEGORYID");

      this.setState({
        ...this.state,
        mainMenu: menu,
        treeMenu: menu2,
        showMenu: pos >= 0 ? true : false,
        storeid: storeid,
      });

      await this.setMenu();
      await this.getProducts();
      this.handleResize();
      window.addEventListener("resize", this.handleResize);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }
  setMenu = async () => {
    try {
      var menu = [];

      await callApi("init", "init");

      setTimeout(
        function () {
          menu = this.state.mainMenu;
          var categoryid = this.props.match.params.categoryid;
          //console.log(categoryid)
          var categories = [];
          var categoryLabel = "";
          var categoryImage = "";
          var categoryHomeLabel = "";
          var jsonMenu = "[ ";

          var _categoryRoute = "";
          var categoryRoute = [];
          var categoryRouteObj = [];

          menu.map(function (key) {
            if (key.LANGUAGEID == localStorage.getItem("def_language")) {
              categories.push(key);
              if (key.CATEGORYID == categoryid) {
                categoryLabel = key.CATEGORYLABEL;
                _categoryRoute = key.CATEGORYIDROOT;
                if (key.CATEGORYLEVEL == 1) {
                  categoryImage = key.IMAGEPATH;
                }
              }
              if (key.CATEGORYID == 0) {
                categoryHomeLabel = key.CATEGORYLABEL; //.toUpperCase()
              }
            }
          });

          menu.map(function (key) {
            if (
              key.LANGUAGEID == localStorage.getItem("def_language") &&
              key.statusid == 5
            ) {
              if (key.CATEGORYLEVEL == 1) {
                var label1 = key.CATEGORYLABEL;
                var ISPARENT1 = searchParent(menu, key.CATEGORYID, "PARENTID");
                jsonMenu +=
                  ' { "key": "' +
                  key.CATEGORYID +
                  '", "label" : "' +
                  key.CATEGORYLABEL +
                  '"';
                if (ISPARENT1) {
                  jsonMenu += ', "nodes": [ ';
                  menu.map(function (key2) {
                    if (
                      key.CATEGORYID == key2.PARENTID &&
                      key2.CATEGORYLEVEL == 2
                    ) {
                      jsonMenu +=
                        ' { "key": "' +
                        key2.CATEGORYID +
                        '", "label" : "' +
                        key2.CATEGORYLABEL +
                        '"';
                      var ISPARENT2 = searchParent(
                        menu,
                        key2.CATEGORYID,
                        "PARENTID"
                      );
                      if (ISPARENT2) {
                        jsonMenu += ', "nodes": [ ';
                        menu.map(function (key3) {
                          if (
                            key2.CATEGORYID == key3.PARENTID &&
                            key3.CATEGORYLEVEL == 3
                          ) {
                            jsonMenu +=
                              ' { "key": "' +
                              key3.CATEGORYID +
                              '", "label" : "' +
                              key3.CATEGORYLABEL +
                              '"';
                            var ISPARENT3 = searchParent(
                              menu,
                              key3.CATEGORYID,
                              "PARENTID"
                            );
                            if (ISPARENT3) {
                              jsonMenu += ', "nodes": [ ';
                              menu.map(function (key4) {
                                if (
                                  key3.CATEGORYID == key4.PARENTID &&
                                  key4.CATEGORYLEVEL == 4
                                ) {
                                  jsonMenu +=
                                    ' { "key": "' +
                                    key4.CATEGORYID +
                                    '", "label" : "' +
                                    key4.CATEGORYLABEL +
                                    '"';
                                }
                              });
                              jsonMenu += "]},";
                            } else {
                              jsonMenu += "},";
                            }
                          }
                        });
                        jsonMenu += "]},";
                      } else {
                        jsonMenu += "},";
                      }
                    }
                  });
                  jsonMenu += "]},";
                } else {
                  jsonMenu += "},";
                }
              }
            }
          });
          jsonMenu += "]";
          categoryRoute = _categoryRoute.split(">");
          var activeNodes = [];
          for (let key in categoryRoute) {
            for (let cat in menu) {
              if (categoryRoute[key] == menu[cat].CATEGORYID) {
                categoryRouteObj.push({
                  label: menu[cat].CATEGORYLABEL,
                  categoryid: menu[cat].CATEGORYID,
                });

                if (categoryRoute[key] != "0") {
                  if (activeNodes.length > 0) {
                    let prepos = activeNodes[activeNodes.length - 1];
                    activeNodes.push(prepos + "/" + categoryRoute[key]);
                  } else {
                    activeNodes.push(categoryRoute[key]);
                  }
                }
                var initialActiveKey = activeNodes[activeNodes.length - 1];
              }
            }
          }

          var cats = window.location.pathname.split("/");
          cats.splice(0, 1);

          var catsIds = [];
          menu.map(function (key) {
            catsIds.push({
              id: key.CATEGORYID,
              label: key.CATEGORYLABEL,
              path: formatPathName(key.CATEGORYLABEL),
            });
          });

          var breadcrumbs = [];
          var link = "";
          for (let c = 0; c < cats.length - 1; c++) {
            let cat = cats[c];
            link = link + "/" + cat + this.state.aditionalInfo;
            let id = findValueById(catsIds, cat, "path", "id");
            let label = findValueById(catsIds, cat, "path", "label");

            if (label === "NOT FOUND") {
              label = cat.split("-").join(" ");
              label = label.toLowerCase();
            }
            breadcrumbs.push({
              label: label,
              categoryid: id === "NOT FOUND" ? 0 : id,
              link: id === "NOT FOUND" ? "#" : link + "/" + id,
            });
          }

          //console.log("breadcrumbs", breadcrumbs)
          var categoryObj = {
            orgName: process.env.REACT_APP_ORG_NAME,
            categoryRouteObj: breadcrumbs,
          };

          var haveError = true;
          var estado = 0;
          try {
            jsonMenu = jsonMenu.replaceAll("},]", "}]");
          } catch (e) {
            console.log("EXC SO");
            while (haveError && estado < 500000) {
              if (jsonMenu.indexOf("},]") > 0) {
                jsonMenu = jsonMenu.replace("},]", "}]");
              } else {
                haveError = false;
              }
              estado++;
            }
          }

          var treeDataMenu = JSON.parse(jsonMenu);
          var MetaTags = {};

          setSpanLabel(treeDataMenu);
          treeDataMenu.map(function (key) {
            if (key.nodes) {
              setSpanLabel(key.nodes);
              key.nodes.map(function (key2) {
                if (key2.nodes) {
                  setSpanLabel(key2.nodes);
                  key2.nodes.map(function (key3) {
                    if (key3.nodes) {
                      setSpanLabel(key3.nodes);
                    }
                  });
                }
              });
            }
          });
          try {
            let pos = find(this.state.mainMenu, categoryid, "CATEGORYID");
            let _cat = this.state.mainMenu[pos];

            let categoryTitle = _cat.METATAGTITLE
              ? _cat.METATAGTITLE
              : _cat.CATEGORYLABEL;
            let categoryDescription = categoryTitle;

            if (_cat.METATAGDESCRIPTION && _cat.METATAGDESCRIPTION != null) {
              if (_cat.METATAGDESCRIPTION !== categoryTitle) {
                categoryDescription = _cat.METATAGDESCRIPTION;
              }
            }

            MetaTags = {
              title: categoryTitle,
              path: window.location.href,
              description: categoryDescription,
              names: [
                { name: "description", content: categoryDescription },
                { name: "keywords", content: categoryTitle.replace(" ", ",") },
                { name: "robots", content: "INDEX,FOLLOW" },
              ],
              properties: [
                {
                  id: "og-title",
                  property: "og-title",
                  content: categoryTitle,
                },
                { id: "og-title", property: "og:type", content: "website" },

                {
                  id: "og-description",
                  property: "og:description",
                  content: categoryDescription,
                },
                {
                  id: "og-url",
                  property: "og:url",
                  content: window.location.href,
                },
              ],
            };
          } catch (e) {}
          //console.log("MetaTags",MetaTags)
          this.setState({
            ...this.state,
            MetaTags,
            initialActiveKey: initialActiveKey,
            activeNodes: activeNodes,
            categoryObj: categoryObj,
            categories: categories,
            categoryid: categoryid,
            categoryImage: categoryImage,
            categoryLabel: categoryLabel,
            categoryHomeLabel: categoryHomeLabel,
            treeDataMenu: treeDataMenu,
          });
        }.bind(this),
        50
      );
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    } finally {
      this.setState({ redirect: false, isLoading: false });
    }
  };

  getProducts = async () => {
    try {
      this.setState({ ...this.state, isLoading: true });

      await getStoresInfo();

      var data = {
        languageid: getLanguage(),
        categoryid: this.props.match.params.categoryid,
        storeid: this.state.storeid,
      };

      var resp = await callApi("customer", "getFilterProducts", data);

      if (resp.rows.length <= 0) {
        this.setState({
          isLoading: false,
          productNotFounded: true,
          messages: [getDefValues().notFoundsubTitleLabel1],
        });
      } else {
        let categoryDescription = "";
        let categoryDescriptionH = "";
        let pos = find(
          this.state.mainMenu,
          this.props.match.params.categoryid,
          "CATEGORYID"
        );
        let cat = this.state.mainMenu[pos];
        if (cat.DESCRIPTION && cat.DESCRIPTION != null) {
          if (cat.DESCRIPTION !== cat.CATEGORYLABEL) {
            categoryDescription = cat.DESCRIPTION;

            let tempCat = formatCategory(cat.DESCRIPTION);
            //console.log("tempCat",tempCat)
            categoryDescriptionH = tempCat; //_cat.METATAGDESCRIPTION
          }
        }

        let categoryTitle = cat.METATAGTITLE
          ? cat.METATAGTITLE
          : cat.CATEGORYLABEL;
        let categoryMetaDescription = categoryTitle;
        if (cat.METATAGDESCRIPTION && cat.METATAGDESCRIPTION != null) {
          if (cat.METATAGDESCRIPTION !== categoryTitle) {
            categoryMetaDescription = cat.METATAGDESCRIPTION;
          }
        }
        var MetaTags = {
          title: categoryTitle,
          path: window.location.href,
          description: categoryDescription,
          names: [
            { name: "description", content: categoryMetaDescription },
            { name: "keywords", content: categoryTitle.replace(" ", ",") },
            { name: "robots", content: "INDEX,FOLLOW" },
          ],
          properties: [
            { id: "og-title", property: "og-title", content: categoryTitle },
            { id: "og-title", property: "og:type", content: "website" },

            {
              id: "og-description",
              property: "og:description",
              content: categoryMetaDescription,
            },
            { id: "og-url", property: "og:url", content: window.location.href },
          ],
        };
        let respCat = await buildProductsCard(
          resp.rows,
          this.managepagination,
          this.manageCart,
          this.state.prodDefConfig,
          "categories",
          this.state.categoryObj.categoryRouteObj,
          this.props.match.params.categoryid
        );

        let cardConfig = respCat.cardConfig;
        if (this.props.windowWidth < 767) {
          delete cardConfig["managepagination"];
          delete cardConfig["pagesettings"];
          cardConfig["mobile"] = true;
        }
        //  			console.log("cardConfig",cardConfig)
        var listS = [];
        var listI = [];
        var listP = [];
        var items = [];

        var categoryLabelP = this.state.categoryLabel;
        var categoryidP = this.state.categoryid;

        resp.rows.map(function (key, index) {
          listS.push(key.productid + "");
          listI.push(key.productid);
          listP.push({
            id: key.productid + "",
            name: key.productname,
            price: key.price,
            brand: "CasaIdeas",
            category: key.category,
            list: key.categories.toString(),
            quantity: "1",
            position: index + 1,
          });

          items.push({
            item_id: key.productid + "",
            item_name: key.productname,
            coupon: "",
            discount: 0,
            index: index,
            item_list_name: "category: " + categoryLabelP,
            item_list_id: "categoryid: " + categoryidP,
            affiliation: "Google Store",
            item_brand: "Casa Ideas",
            item_category: categoryLabelP,
            item_variant: "",
            price: key.price,
            currency: "BOB",
            quantity: 1,
          });
        });

        var login = getLoginInfo();
        let storesInfo = await getStoresInfo();
        let _pos = find(storesInfo.stores, this.state.storeid, "storeId");
        var storeName = storesInfo.stores[_pos].storeName;
        var objCategory = {
          current_view: "category",
          current_list: "category: " + this.state.categoryLabel,
          current_id: "categoryid: " + this.state.categoryid,
          current_currency: "BOB",
          userId: login.userId ? login.userId : "0",
          string_searched: "",
          store_name: process.env.REACT_APP_ORG_NAME + "-" + storeName,
          //gtm: {uniqueEventId: 1407, start: 1649342569890},
          googleDynamicRemarketing: {
            ecomm_pcat: [this.state.categoryLabel],
            ecomm_pagetype: "category",
            ecomm_prodid: listS,
          },
          criteoParams: {
            PageType: "ListingPage",
            ProductIDList: listI,
            email: "",
          },
          gdpr_marketing_status: "accepted",
          gdpr_statistics_status: "accepted",
          ecommerce: {
            currencyCode: "BOB",
            impressions: listP,
          },
          items: items,
        };
        setGTMEvent("category", objCategory);

        this.setState({
          ...this.state,
          isLoading: false,
          MetaTags: MetaTags,
          categoryDescriptionH: categoryDescriptionH,
          cardConfig: respCat.cardConfig,
          cardData: respCat.cardProducts,
          categoryDescription: categoryDescription,
        });
        var _event = {
          target: {
            name: "sort",
            value: this.state.sortmethod,
          },
        };
        this.managesort(_event);
      }
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    } finally {
      this.setState({ redirect: false, isLoading: false });
    }
  };

  manageCart = async (event) => {
    try {
      this.setState({ isLoading2: true });
      var resp = await manageCart(
        event,
        this.state.cardData,
        this.manageCart,
        toast
      );
      if (resp.success) {
        if (!resp.redirect) {
          this.setState({
            ...this.state,
            isLoading: false,
            //cardData: resp._cardData
          });
          //console.log("resp", resp)
          if (resp.dataCart) {
            this.props.updateCart(resp.dataCart);
          } else {
            if (resp.isEvent) {
              this.props.addQtyEvent(resp.eventQty);
            }
          }
        } else {
          this.setState({
            ...this.state,
            isLoading: false,
            selectedProductID: resp.selectedProductID,
            selectedPath: resp.selectedPath,
            selectedCategory: resp.selectedCategory,
            redirectProductDetails: true,
          });
          window.location.reload();
        }
      } else {
        this.setState({ ...this.state, isLoading: false });
        console.log(">>>>>>>", resp.message);
      }

      this.setState({ isLoading2: false });
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };
  cardHistoryControl = (page) => {
    if (this.state.cardConfig.pagesettings) {
      if (page != this.state.cardConfig.pagesettings?.currentPage) {
        //console.log("SETT" ,page, this.state.cardConfig.pagesettings )
        const cardConfig = this.state.cardConfig;
        cardConfig["pagesettings"]["currentPage"] = page;
      }
    } else {
      //console.log("no listo")
    }
  };
  managepagination = (event) => {
    window.scrollTo(0, 0);
    this.setState({ isLoading: true });
    setTimeout(
      function () {
        var newpagesettings = managepagination(
          this.state.cardConfig.pagesettings,
          event
        );
        var newcardConfig = {
          pagewidth: "col-md-12 col-lg-12 col-xl-12",
          colsize: "col-6",
          colsizelg: "col-lg-2_5",
          colsizemd: "col-md-4",
          colsizesm: "col-sm-6",
          pagination: true,
          managepagination: this.managepagination,
          pagesettings: newpagesettings,
          /*overtop            :"YES"*/
        };
        this.setState({ cardConfig: newcardConfig, isLoading: false });
      }.bind(this),
      1000
    );
  };
  managesort = (event) => {
    this.setState({ isLoading: true });
    setTimeout(
      function () {
        var newcardData = managesort(this.state.cardData, event);
        this.setState({ cardData: newcardData, isLoading: false });
      }.bind(this),
      1000
    );
  };

  toggleCategoryMenu = () => {
    let state = this.state.showCategoryMenu === false ? true : false;
    this.setState({ showCategoryMenu: state });
  };

  navigate = async (key, event, props) => {
    var newcategoryid = key.split("/")[key.split("/").length - 1];

    var pos = find(this.state.mainMenu, newcategoryid, "CATEGORYID");
    var newPath =
      "/" +
      formatPathCategory(this.state.mainMenu[pos].CATEGORYNAME) +
      "/" +
      newcategoryid;
    this.props.history.push(newPath + this.state.aditionalInfo);
    this.setState({
      newcategoryid: newcategoryid,
      newPath: newPath,
      redirect: true,
      isLoading: true,
      showCategoryMenu: false,
    });

    await this.setMenu();
    await this.getProducts();
  };

  setCategory = async (event) => {
    var data;
    if (event.target.name) {
      data = event.target.name;
    } else {
      data = event.target.parentNode.name;
    }
    var newcategoryid = data;

    var newPath = "";
    var pos = find(
      this.state.categoryObj.categoryRouteObj,
      newcategoryid,
      "categoryid"
    );
    if (pos >= 0) {
      newPath = this.state.categoryObj.categoryRouteObj[pos].link;
    } else {
      pos = find(this.state.mainMenu, newcategoryid, "CATEGORYID");

      this.state.categoryObj.categoryRouteObj.map(function (key) {
        newPath = newPath + "/" + formatPathName(key.label);
      });
      newPath =
        newPath +
        "/" +
        formatPathName(this.state.mainMenu[pos].CATEGORYLABEL) +
        "/" +
        newcategoryid;
    }

    this.props.history.push(newPath + this.state.aditionalInfo);

    this.setState({
      newcategoryid: newcategoryid,
      newPath: newPath,
      redirect: true,
      isLoading: true,
      showCategoryMenu: false,
    });
    await this.setMenu();
    await this.getProducts();
  };

  handleResize = () => {
    /*this.setState({
			windowWidth: window.innerWidth
		})*/
    var slidesToShow = 0;
    if (window.innerWidth > 1250) {
      slidesToShow = 6;
    } else if (window.innerWidth <= 1250 && window.innerWidth > 950) {
      slidesToShow = 5;
    } else if (window.innerWidth <= 950 && window.innerWidth > 768) {
      slidesToShow = 4;
    } else {
      slidesToShow = 3;
    }
    var settingsSlider = {
      dots: false,
      adaptiveHeight: true,
      arrows: true,
      autoplay: false,
      cssEase: "linear",
      slidesToShow: slidesToShow,
      slidesToScroll: 1,
      speed: 500,
      divWidth: "card col-md-12 col-lg-12 col-xl-12",
      specialArrows: "2",
      outside: "YES",
      spaceitems: "YES",
    };

    this.setState({
      //windowWidth: window.innerWidth,
      settingsSlider: settingsSlider,
    });
  };

  changeHandler = async (event) => {
    this.setState({ searchkey: event.target.value });
    if (event.key === "Enter") {
      await this.searchProducts(event);
    }
  };

  searchProducts = async (event) => {
    try {
      this.setState({ isLoading: true });
      let resp = await callApi("customer", "getFilterProductsNew", {
        searchkey: this.state.searchkey,
        storeid: this.state.storeid,
      });

      if (resp.rows.length >= 1) {
        let respCat = await buildProductsCard(
          resp.rows,
          this.managepagination,
          this.manageCart,
          this.state.prodDefConfig,
          "categories",
          this.state.categoryObj.categoryRouteObj,
          this.props.match.params.categoryid
        );
        this.setState({
          ...this.state,
          cardConfig: respCat.cardConfig,
          cardData: respCat.cardProducts,
          activeSearch: true,
        });
      } else {
        let respCat = await buildProductsCard(
          resp.rows,
          this.managepagination,
          this.manageCart,
          this.state.prodDefConfig,
          "categories",
          this.state.categoryObj.categoryRouteObj,
          this.props.match.params.categoryid
        );

        let cardConfig = respCat.cardConfig;
        if (this.props.windowWidth < 767) {
          delete cardConfig["managepagination"];
          delete cardConfig["pagesettings"];
          cardConfig["mobile"] = true;
        }

        this.setState({
          ...this.state,
          cardConfig: respCat.cardConfig,
          cardData: respCat.cardProducts,
          activeSearch: true,
        });
        var _event = {
          target: {
            name: "sort",
            value: this.state.sortmethod,
          },
        };
        this.managesort(_event);
      }
    } catch (Excep) {
      console.log(">>>>>>>", Excep);
    } finally {
      this.setState({ isLoading: false });
    }
  };

  render() {
    if (this.state.noCategory) {
      return "";
    } else {
      return this.props.showSearcher ? (
        ""
      ) : (
        <>
          <Helmet>
            <meta charSet="utf-8" />
            <title>{this.state.MetaTags.title}</title>
            <link rel="canonical" href={this.state.MetaTags.path} />
            <meta name="title" content={this.state.MetaTags.title} />
            <meta
              name="description"
              content={this.state.MetaTags.description}
            />
          </Helmet>

          {this.state.redirectProductDetails ? (
            <Redirect
              to={{
                pathname:
                  (this.state.selectedCategory
                    ? "/" + this.state.selectedCategory + "/"
                    : "/productDetails/") +
                  (this.state.selectedPath ? this.state.selectedPath : "show"),
              }}
            />
          ) : (
            ""
          )}
          {this.state.redirect === true ? (
            <Redirect to={this.state.newPath} />
          ) : (
            ""
          )}
          <PageLoading
            isLoading={this.state.isLoading}
            MetaTags={this.state.MetaTags}
          />
          <PageLoading isLoading={this.state.isLoading2} />
          {this.state.productNotFounded ? (
            <NotFounded messages={this.state.messages} />
          ) : (
            <>
              <div className="page-wrapper" onClick={this.props.hideCart}>
                <div className="content custom-container container-page container-fluid container-page">
                  <div className="categoryRoot mt-4 mb-30">
                    <>{this.state.categoryObj.orgName + " > "}</>
                    {!this.state.activeSearch &&
                      this.state.categoryObj.categoryRouteObj.map(
                        (cat, index) =>
                          cat.categoryid == this.state.categoryid ? (
                            <>
                              {index == 1 ? (
                                <span>{cat.label}</span>
                              ) : (
                                <span>{cat.label}</span>
                              )}
                            </>
                          ) : cat.categoryid > 0 ? (
                            <>
                              {index === 1 ? (
                                <button
                                  className="transparent-button"
                                  id={cat.categoryid}
                                  name={cat.categoryid}
                                  onClick={this.setCategory}
                                >
                                  {" "}
                                  {cat.label + " > "}
                                </button>
                              ) : (
                                <button
                                  className="transparent-button"
                                  id={cat.categoryid}
                                  name={cat.categoryid}
                                  onClick={this.setCategory}
                                >
                                  {" "}
                                  {cat.label + " > "}
                                </button>
                              )}
                            </>
                          ) : (
                            <>{cat.label + " > "}</>
                          )
                      )}
                  </div>

                  {this.state.action === "addEventList" && (
                    <div className="row">
                      <div className="col-sm-12 text-right">
                        <button
                          className={
                            "btn col-6 col-md-4 col-lg-2 btn-secondary btn-sm ml-1"
                          }
                          type="button"
                          onClick={() => {
                            this.props.history.push(
                              "/customer/event/manage/addProducts_" +
                                this.props.eventId
                            );
                          }}
                        >
                          {getDefValues().gobackMyEvent}
                        </button>
                      </div>
                    </div>
                  )}

                  <div className="row mb-4">
                    {!this.state.showMenu ? (
                      <div className="col-md-6 col-lg-6 col-sm-12 flex middle">
                        {!this.state.activeSearch && (
                          <h1 className="h3-responsive span-title">
                            {" "}
                            {this.state.categoryLabel}
                          </h1>
                        )}
                      </div>
                    ) : (
                      <div className="col-md-6 col-lg-6 col-sm-12 flex middle">
                        <>
                          {!this.state.showCategoryMenu ? (
                            <>
                              <i
                                onClick={this.toggleCategoryMenu}
                                className="h3-responsive fa fa-bars"
                              />
                              {!this.state.activeSearch && (
                                <>
                                  {this.state.categoryImage !== "" ? (
                                    <img
                                      src={getImagePath(
                                        this.state.categoryImage
                                      )}
                                      className="category-img"
                                      alt=""
                                    />
                                  ) : (
                                    <span className="ml-3" />
                                  )}
                                </>
                              )}
                              {!this.state.activeSearch && (
                                <h1 className="h3-responsive span-title">
                                  {" "}
                                  {this.state.categoryLabel}
                                </h1>
                              )}
                            </>
                          ) : (
                            <>
                              <span onClick={this.toggleCategoryMenu}>
                                <i className="h3-responsive fas fa-times mr-1" />
                              </span>
                              <div className="subcategory-menu-list mt-2">
                                <span className="h3-responsive">
                                  {this.state.categoryHomeLabel}
                                </span>

                                <TreeMenu
                                  data={this.state.treeDataMenu}
                                  search={false}
                                  hasSearch={false}
                                  onClickItem={async ({
                                    key,
                                    label,
                                    ...props
                                  }) => {
                                    await this.navigate(key, label, props); // user defined prop
                                  }}
                                  initialOpenNodes={this.state.activeNodes}
                                  initialActiveKey={this.state.initialActiveKey}
                                />
                              </div>
                            </>
                          )}
                        </>
                      </div>
                    )}
                    <div className="col-md-6 col-lg-6	col-sm-12">
                      <div className="card text-right">
                        <div className="row mt-3">
                          {this.state.action === "addEventList" && (
                            <div className="mb-2 col-sm-12 col-md-12 col-lg-7 flex items-right">
                              <input
                                key={"search_input"}
                                className="pagination-search-input"
                                value={this.state.searchkey}
                                onChange={this.changeHandler}
                                onKeyDown={this.changeHandler}
                                type="text"
                                name="searchkey"
                                placeholder={getDefValues().searchProductLabel}
                              />
                              <button
                                className={"btn btn-secondary btn-sm ml-1"}
                                type="button"
                                style={{ width: "25%" }}
                                onClick={this.searchProducts}
                              >
                                {getDefValues().searchTitleLabel}
                              </button>
                            </div>
                          )}

                          <div className="col-sm-12 col-md-12 col-lg-5 mt-1">
                            {getDefValues().orderByLabel + ": "}
                            <select
                              id="sort"
                              name="sort"
                              className="pagination-select"
                              defaultValue={this.state.sortmethod}
                              onChange={this.managesort}
                            >
                              {getSortMethods().map((item) => (
                                <option
                                  key={"sort_i" + item.value}
                                  value={item.value}
                                >
                                  {item.text}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="mobile-col col-md-12 col-lg-12	">
                      <Category
                        categories={this.state.categories}
                        setCategory={this.setCategory}
                        categoryid={this.state.categoryid}
                        settingsSlider={this.state.settingsSlider}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="mobile-col col-md-12 col-lg-12">
                      {this.state.isLoading ? (
                        ""
                      ) : (
                        <>
                          {this.props.windowWidth < 767 ? (
                            <InfiniteScroll
                              dataLength={this.state.items.length}
                              next={this.fetchMoreData}
                              hasMore={this.state.hasMore}
                              loader={
                                <h4>{getDefValues().loadingProductsLabel}</h4>
                              }
                              endMessage={
                                <p style={{ textAlign: "center" }}>
                                  <b>{getDefValues().endOfResultsLabel}</b>
                                </p>
                              }
                            >
                              <Card
                                cardData={this.state.cardData}
                                cardConfig={this.state.cardConfig}
                              />
                            </InfiniteScroll>
                          ) : (
                            <Card
                              cardData={this.state.cardData}
                              cardConfig={this.state.cardConfig}
                              cardHistoryControl={this.cardHistoryControl}
                            />
                          )}
                        </>
                      )}
                    </div>
                  </div>
                  {Array.isArray(this.state.categoryDescriptionH) ? (
                    <div className="row mt-30 mb-30 flex items-center">
                      <div className="mobile-col col-md-12 col-lg-10 text-center">
                        <div>
                          {this.state.categoryDescriptionH.map((item) => (
                            <>
                              {item.text}

                              {item.link && (
                                <>
                                  <a
                                    style={{
                                      textDecoration: "underline",
                                      cursor: "pointer",
                                      fontWeight: "bold",
                                      color: "#d50032",
                                    }}
                                    href={item.link}
                                  >
                                    {" "}
                                    {item.linktext}
                                  </a>
                                </>
                              )}
                            </>
                          ))}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {this.state.categoryDescriptionH !== "" ? (
                        <div className="row mt-30 mb-30 flex items-center">
                          <div className="mobile-col col-md-12 col-lg-10 text-center">
                            <span> {this.state.categoryDescriptionH}</span>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      );
    }
  }
}
export default page;

/* eslint-disable array-callback-return */
/*****components***/
import React, { Component } from "react";
import { toast } from "mdbreact";
import { Redirect } from "react-router-dom";
/*custom components*/
import PageLoading from "../../components/PageLoading";

import Modal from "../../components/Modal";
import Form from "../../components/Form";

import Card from "../../components/Card";
import List from "../../components/List";
import BlogContainer from "../../components/BlogContainer";
import Slider from "../../components/Slider";
import SliderImage from "../../components/SliderImage";
import Text from "../../components/Text";
import Button from "../../components/Button";
import Image from "../../components/Image";
import Video from "../../components/Video";
import CategoryBlog from "../../components/CategoryBlog";

/*functions*/
import {
  manageCart,
  getShopCart,
  manageButton,
  managepagination,
  managesort,
  findObjName,
  toFixed,
  setGTMEvent,
  find,
  getLoginInfo,
  getStoresInfo,
  changeHandlerManager,
  toggleModalManager,
  loadPageV2,
  callApi,
} from "../../common/functions";

var windowWidth = window.innerWidth;
const foldername = process.env.REACT_APP_FOLDER_LAYOUT;

class page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isLoading2: false,
      mainClass: "page-wrapper",
      redirectProductDetails: false,
      selectedProductID: 0,
      selectedPath: "",
      selectedCategory: "",
      pagetitle: "",
      pagedesc: "",
    };
  }

  /*loading data*/
  componentDidMount = async () => {
    try {
      this.props.hideCart();
      getShopCart("CUSTOMPAGE");
      let _cart = this.props.shopCart;
      var products = [];
      _cart.products.map(function (key) {
        var variant = "";
        key.optionsselected.map(function (v) {
          variant = variant + "," + v.optionvalue;
        });
        variant = variant.substr(1, variant.length);
        products.push({
          id: key.productid,
          sku: key.productsku,
          name: key.productname,
          price: parseFloat(toFixed(key.price)),
          brand: "CasaIdeas",
          category: key.category,
          variant: variant,
          quantity: key.quantity,
        });
      });

      let storesInfo = await getStoresInfo();
      let pos = find(storesInfo.stores, storesInfo.selectedStoreId, "storeId");
      var storeName = storesInfo.stores[pos].storeName;
      var objData = {
        current_view: "pages/" + this.props.match.params.page,
        current_list: "pages/" + this.props.match.params.page,
        current_currency: "BOB",
        userId: getLoginInfo().userId ? getLoginInfo().userId : "0",
        cart_products: products,
        string_searched: "",
        store_name: process.env.REACT_APP_ORG_NAME + "-" + storeName,
        googleDynamicRemarketing: { ecomm_pagetype: "home" },
        event: "gtm.load",
        criteoParams: {
          PageType: "pages/" + this.props.match.params.page,
          email: "",
        },
        gdpr_marketing_status: "accepted",
        gdpr_statistics_status: "accepted",
      };

      setGTMEvent("loadPage", objData);

      var params = this.props.match.params;
      var finalFoldername = foldername;
      var notFounded = true;
      if (params.folder4 && notFounded) {
        finalFoldername =
          finalFoldername +
          params.folder1 +
          "/" +
          params.folder2 +
          "/" +
          params.folder3 +
          "/" +
          params.folder4 +
          "/";
        notFounded = false;
      }
      if (params.folder3 && notFounded) {
        finalFoldername =
          finalFoldername +
          params.folder1 +
          "/" +
          params.folder2 +
          "/" +
          params.folder3 +
          "/";
        notFounded = false;
      }
      if (params.folder2 && notFounded) {
        finalFoldername =
          finalFoldername + params.folder1 + "/" + params.folder2 + "/";
        notFounded = false;
      }
      if (params.folder1 && notFounded) {
        finalFoldername = finalFoldername + params.folder1 + "/";
        notFounded = false;
      }

      var resp = await callApi("layaoutManager", "getLayoutTemplateByName", {
        layoutFile: params.page,
        foldername: finalFoldername,
      });

      let pagetitle = "";
      let pagedesc = "";
      let pagemetatitle;
      let pagemetadesc;
      let pagetags = "";

      resp.template.map(function (key) {
        if (key.componentType === "ABOUT") {
          if (key.data.title) {
            pagetitle = key.data.title;
          }
          if (key.data.about) {
            pagedesc = key.data.about;
          }
          if (key.data.metatitle) {
            pagemetatitle = key.data.metatitle;
          }
          if (key.data.tags) {
            pagetags = key.data.tags;
          }
          if (key.data.metadesc) {
            pagemetadesc = key.data.metadesc;
          }
        }
      });
      let MetaTags;
      if (pagemetatitle) {
        MetaTags = {
          title: pagetitle,
          names: [
            { name: "description", content: pagemetadesc },
            { name: "keywords", content: pagetags },
            { name: "robots", content: "INDEX,FOLLOW" },
          ],
          properties: [
            { id: "og-title", property: "og-title", content: pagetitle },
            { id: "og-title", property: "og:type", content: "page" },
            {
              id: "og-description",
              property: "og:description",
              content: pagemetadesc,
            },
            { id: "og-url", property: "og:url", content: window.location.href },
          ],
        };
      }

      let _pageData = await loadPageV2(
        resp.template,
        this.managepagination,
        this.manageCart,
        this.changeHandler,
        this.manageButton,
        this.toggleModal,
        windowWidth
      );
      this.setState({
        template: resp.template,
        MetaTags: MetaTags,
        pagetitle: pagetitle,
        pagedesc: pagedesc,
      });

      this.renderData(_pageData);

      //this.setState({pageData:_pageData, template: resp.template});
    } catch (err) {
      console.log(">>>>>>>>>>", err);
    } finally {
      this.setState({ isLoading: false });
    }
    window.addEventListener("resize", this.handleResize);
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.handleResize);
  }

  handleResize = async () => {
    var dif = (window.innerWidth * 100) / this.state.windowWidth;

    this.setState({
      windowWidth: window.innerWidth,
      windowHeight: window.innerHeight,
    });

    if (dif <= 40 || dif >= 250) {
      this.setState({ isLoading: true });
      let _pageData = await loadPageV2(
        this.state.template,
        this.managepagination,
        this.manageCart,
        this.changeHandler,
        this.manageButton,
        this.toggleModal,
        window.innerWidth
      );
      this.renderData(_pageData);
      this.setState({ isLoading: false });
    }
  };

  renderData = (_pageData) => {
    try {
      for (let r in _pageData) {
        this.setState({
          ["RENDEROBJ" + _pageData[r].ObjectName]: _pageData[r],
        });
      }
      //console.log("-final-", this.state)
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
    }
  };

  toggleModal = (event) => {
    let object = findObjName(event);
    //console.log(object, this.state["RENDEROBJ"+object])
    let newTemplate = toggleModalManager(this.state["RENDEROBJ" + object]);
    this.setState({ ["RENDEROBJ" + object]: newTemplate });
  };

  changeHandler = async (event) => {
    let object = findObjName(event);
    let fieldName = object.split("__");
    let newTemplate = changeHandlerManager(
      this.state["RENDEROBJ" + fieldName[0]],
      fieldName[1],
      event.target.value
    );
    this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
  };

  changeForm = async (event) => {
    this.setState({ isLoading: true });
    let object = findObjName(event);
    let fieldName = object.split("__");
    let newTemplate = this.state["RENDEROBJ" + fieldName[0]];
    newTemplate["activeForm"] = event.target.value;
    this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
    setTimeout(
      function () {
        this.setState({ ["RENDEROBJ" + fieldName[0]]: newTemplate });
        this.setState({ isLoading: false });
      }.bind(this),
      250
    );
  };

  manageCart = async (event) => {
    //let object = findObjName(event);
    let container;
    if (event.target.name) {
      container = event.target.id;
    } else {
      container = event.target.parentNode.id;
    }

    try {
      this.setState({ isLoading2: true });
      var resp = await manageCart(
        event,
        this.state["RENDEROBJ" + container].data,
        this.manageCart,
        toast
      );
      if (resp.success) {
        if (!resp.redirect) {
          this.setState({
            ...this.state,
            isLoading: false,
            //cardData: resp._cardData
          });
          if (resp.dataCart) {
            this.props.updateCart(resp.dataCart);
          }
        } else {
          this.setState({
            ...this.state,
            isLoading: false,
            selectedProductID: resp.selectedProductID,
            selectedPath: resp.selectedPath,
            selectedCategory: resp.selectedCategory,
            redirectProductDetails: true,
          });
        }
      } else {
        this.setState({ ...this.state, isLoading: false });
        console.log(">>>>>>>", resp.message);
      }

      this.setState({ isLoading2: false });
    } catch (Excep) {
      this.setState({ isLoading2: false });
      console.log(">>>>>>>", Excep);
    }
  };

  manageButton = async (event) => {
    try {
      this.setState({ isLoading2: true });
      let object = findObjName(event);
      var fieldName = object.split("__");
      await manageButton(fieldName[1], this.state, toast);
      /*
			if (resp.response){
				showMessage(toast,resp.message );	
			}else{
				showError(toast,resp.message)
			}			
			*/
      this.setState({ isLoading2: false });
    } catch (err) {
      console.log(">>>>>>>>>>>", err);
      this.setState({ isLoading2: false });
    }
  };

  managepagination = (event) => {
    this.setState({ isLoading: true });

    //let object = findObjName(event);
    let _container;
    if (event.target.name) {
      _container = event.target.id.split("__");
    } else {
      _container = event.target.parentNode.id.split("__");
    }
    let container = _container[0];
    setTimeout(
      function () {
        var newpagesettings = managepagination(
          this.state["RENDEROBJ" + container].config.pagesettings,
          event
        );
        var newcardConfig = {
          pagewidth: "col-md-12 col-lg-12 col-xl-12",
          colsize: "col-6",
          colsizelg: "col-lg-2_5",
          colsizemd: "col-md-4",
          colsizesm: "col-sm-6",
          pagination: true,
          managepagination: this.managepagination,
          pagesettings: newpagesettings,
          /*overtop            :"YES"*/
        };
        this.setState({ cardConfig: newcardConfig, isLoading: false });
      }.bind(this),
      1000
    );
  };
  managesort = (event) => {
    this.setState({ isLoading: true });
    //console.log(event.target)
    //let object = findObjName(event);
    let container;
    if (event.target.name) {
      container = event.target.id;
    } else {
      container = event.target.parentNode.id;
    }
    setTimeout(
      function () {
        var newcardData = managesort(
          this.state["RENDEROBJ" + container],
          event
        );
        this.setState({ cardData: newcardData, isLoading: false });
      }.bind(this),
      1000
    );
  };

  render() {
    return this.props.showSearcher ? (
      ""
    ) : (
      <>
        <PageLoading
          MetaTags={this.state.MetaTags}
          isLoading={this.state.isLoading}
        />
        <PageLoading isLoading={this.state.isLoading2} />
        <div className="page-wrapper" onClick={this.props.hideCart}>
          {this.state.redirectProductDetails ? (
            <Redirect
              to={{
                pathname:
                  (this.state.selectedCategory
                    ? "/" + this.state.selectedCategory + "/"
                    : "/productDetails/") +
                  this.state.selectedProductID +
                  "/" +
                  (this.state.selectedPath ? this.state.selectedPath : "show"),
              }}
            />
          ) : (
            ""
          )}

          <div className="content container-page container-fluid custom-container">
            <div className="row mt-2">
              <div className="col-md-12 col-lg-12">
                <div className="card ">
                  {this.state.pagetitle !== "" ? (
                    <div className="row">
                      <div className="col-md-6 col-lg-6 col-sm-12 flex middle">
                        <h1 className="h3-responsive span-title">
                          {" "}
                          {this.state.pagetitle}
                        </h1>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                  {Object.keys(this.state).map((obj) => (
                    <>
                      {obj.startsWith("RENDEROBJ") && !this.state.isLoading ? (
                        <>
                          {this.state[obj].type === "TEXT" ? (
                            <Text
                              name={this.state[obj].ObjectName}
                              textData={this.state[obj].data}
                              textConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "IMAGE" ? (
                            <Image
                              name={this.state[obj].ObjectName}
                              imageData={this.state[obj].data}
                              imageConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "VIDEO" ? (
                            <Video
                              name={this.state[obj].ObjectName}
                              videoData={this.state[obj].data}
                              videoConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "LIST" ? (
                            <List
                              name={this.state[obj].ObjectName}
                              listValues={this.state[obj].data}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "BLOGCONTAINER" ? (
                            <BlogContainer
                              name={this.state[obj].ObjectName}
                              blogContainerData={this.state[obj].data}
                              blogContainerContent={this.state[obj].content}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "CATEGORYBLOG" ? (
                            <CategoryBlog
                              name={this.state[obj].ObjectName}
                              categoryBlogData={this.state[obj].data}
                              categoryBlogContent={this.state[obj].content}
                              categoryBlogConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "FORM" ? (
                            <Form
                              name={this.state[obj].ObjectName}
                              container={this.state[obj].ObjectName}
                              formData={this.state[obj].data}
                              activeForm={this.state[obj].activeForm}
                              changeForm={this.changeForm}
                              changeHandler={this.changeHandler}
                              manageButton={this.manageButton}
                              formConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "SLIDERPRODUCTS" ? (
                            <Slider
                              name={this.state[obj].ObjectName}
                              sliderData={this.state[obj].data}
                              sliderConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "CARDPRODUCTS" ? (
                            <Card
                              name={this.state[obj].ObjectName}
                              cardData={this.state[obj].data}
                              cardConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "CARDCONTAINER" ? (
                            <Card
                              name={this.state[obj].ObjectName}
                              cardData={this.state[obj].data}
                              cardConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "SLIDERIMAGE" ? (
                            <SliderImage
                              sliderData={this.state[obj].data}
                              sliderConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "MODAL" ? (
                            <Modal
                              name={this.state[obj].ObjectName}
                              modalData={this.state[obj].data}
                              modalConfig={this.state[obj].config}
                              toggle={this.toggleModal}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "SLIDER" ||
                          this.state[obj].type === "SLIDERCONTAINER" ? (
                            <Slider
                              name={this.state[obj].ObjectName}
                              sliderData={this.state[obj].data}
                              sliderConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                          {this.state[obj].type === "BUTTON" ? (
                            <Button
                              name={this.state[obj].ObjectName}
                              buttonData={this.state[obj].data}
                              buttonConfig={this.state[obj].config}
                            />
                          ) : (
                            ""
                          )}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ))}

                  {this.state.pagedesc !== "" ? (
                    <div className="row mt-30 mb-30 flex items-center">
                      <div className="mobile-col col-md-12 col-lg-10 text-center">
                        <span className="h5-responsive">
                          {this.state.pagedesc}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default page;
